.login-form {
    text-align: center !important;
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 1rem;
    background: hsl(0, 0%, 100%);
    border-radius: 15px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    transition: all .3s;
  }
  
  .outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    min-height: 60vh;
    background: hsl(0, 0%, 100%);
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    align-items: center;
  }
  
  .outer .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  /* img{
    width: 50%;
    height: auto;
  } */
  
  .modalcustum{
    top: 0;
    border-radius: .3rem;
    max-width: 40vw;
  }
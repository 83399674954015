.step-box{
	margin: 0 auto;
	background: #ffffff;
	box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.13);
	border-radius: 5px;
	padding: 20px;
	padding-bottom: 50px;
	z-index: -20;
}

.appliance-button{
	width: 100% !important;
	max-width: 300px !important;
	height: 56px !important;
	border-color: none !important;
	border-radius: 28px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	border: solid 1px rgba(255, 255, 255, 0.1);
	background-color: var(--primary-color);
	color: #ffffff;
}

.appliance-button:hover{
	border: none !important;
	border-color: none !important;
	border-radius: 28px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	background-color: var(--primary-color);
	color: #ffffff !important;
	cursor: pointer;
	
}

.form-link{
	color: #4f4f4f;
}

.form-link:hover{
	text-decoration: none;
	color: #4f4f4f;
}

.wht-card-box{
	max-width: 530px;
	background:#fff;
	padding: 24px;
	border-radius: .3rem;
	border: solid 1px rgba(0, 0, 0, 0.1);
	box-shadow: 5px 5px 6px 6px rgba(0, 0, 0, 0.08);
}

.wht-card-box .info-label{
	font-size: 16px;
	font-weight: 500 !important;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.2px;
	color: #023473;
	margin-bottom: 9px;
}

.wht-card-box .info-request{
	font-size: 18px;
	font-weight: 300 !important;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0.6px;
	color: #023473;
	margin-bottom: 9px;

}
.apply-caracter-img{
	width: 150px;/*229 vs -264*/
	display: none;
	margin-left: 40%;
}

@media(min-width: 768px){
	.apply-caracter-img{
		display: block;
		width: 20%;
		margin-top: -100px;
		margin-left: 70%;
		position: absolute;
		top: -15%
	}
}

@media(min-width: 1200px){
	.apply-caracter-img{
		margin-left: 70%;
	}
}
.button-modal{
	margin: 0 5px!important;
}
.modal-body{
	padding: 10px 30px!important;
}
.modal-content{
	width: 100%!important
}
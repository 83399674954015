.file-row{
	height: 80px;
	border: solid 1px rgba(0, 0, 0, 0.1);
	border-radius: 5px;  
	box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.13);
	padding-top: 20px;
	padding-left: 25px;
}

.file-row:hover{
	cursor: pointer;
}

.a-file{
	color: #000000;
}

.a-file:hover{
	color: #000000;
	text-decoration: none;
}
.step-container{
	display: flex;
	justify-content: center;
	align-items:center;
}

.step-text{
	color: #D3D3D3 !important;
}

.step-text-complete{
	color: #9094A3 !important;
}

.step-text-complete:hover {
	color: #F24C5A !important;
} 

.progress-marker.custom{
	background:none !important;
	width: 35px !important;
	height: 35px !important;
	border: solid 2px #c2c2c2;
	margin-top: -13px;
}

.progress-text h5:nth-child(1) {
    padding-left: 1rem;
    margin-top: 5px;
    width: 180px;
}
.progress-text h5 {
    font-size: 1rem;
}

.progress-step.is-complete .progress-marker.custom{
	border: none !important;
	/*background-image: radial-gradient(circle at 50% 0, #0d0dab, #39eafe) !important;*/
}

.progress-step.is-complete::after{
	background: #c2c2c2;
}

.imagen{
	background-image: url("../assets/img/stepper-01.svg");
	background-size: contain;
	background-repeat: no-repeat;
	position:absolute;
	width: 50%;
	height: 50px;
	left: -10px;
}

.imagen2{
	background-image: url("../assets/img/stepper-01.svg");
	background-size: contain;
	background-repeat: no-repeat;
	position:absolute;
	width: 50%;
	height: 50px;
	left: -10px;
}
.imagen3{
	background-image: url("../assets/img/stepper-01.svg");
	background-size: contain;
	background-repeat: no-repeat;
	position:absolute;
	width: 50%;
	height: 50px;
	left: -10px;
}
.imagen4{
	background-image: url("../assets/img/stepper-01.svg");
	background-size: contain;
	background-repeat: no-repeat;
	position:absolute;
	width: 50%;
	height: 50px;
	left: -10px;
}
.imagen5{
	background-image: url("../assets/img/stepper-01.svg");
	background-size: contain;
	background-repeat: no-repeat;
	position:absolute;
	width: 50%;
	height: 50px;
	left: -10px;
}
@media screen and (max-width: 991px){
	.imagen,.imagen2,.imagen3,.imagen4,.imagen5{
		background-image: url("../assets/img/stepper-01.svg");
		background-size: contain;
		background-repeat: no-repeat;
		position:absolute;
		width: 50%;
		height: 50px;
		left: -83px;
		transform: rotate(270deg);
		bottom: 68px;
	}
	.progress-text h5:nth-child(1) {
		padding-left: 0;
		margin-top: 0;
	}
	.progress-text h5 {
		font-size: 1rem;
	}

}

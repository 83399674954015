    .backgroundComunity {
    background-size: 100%;
    transform: translate(0%, -10%);
    background-repeat: no-repeat;
    font-size: 1.5rem;
}

.tablet {
    background-size: cover;
    transform: translate(0%, -15%);
    background-position: center;
    background-repeat: no-repeat;
    font-size: 1.5rem;
}
@media (max-width: 850px) {
    .tablet {
        background-size: 170%;
        background-position: calc(51%) calc(37%);

        /* transform: translate(0%, -15%);
        background-position: center;
        background-repeat: no-repeat;
        font-size: 1.5rem; */
    }
}

.imgcontainer{
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.rowcontenttop{
    width: 100%;
    min-height: 40vh;
    transform: translate(3%, 80%);
    position: absolute;
    -webkit-transform: translate(4%, 80%);
    font-size: 3rem;

}
.rowcontentbott{
    width: 100%;
    min-height: 40vh;
    position: absolute;
    bottom: -20%;
}

@media (min-width: 1900px) {
    .backgroundComunity {
        font-size: 2rem;
    }
}

@media screen and (max-width: 720px) {
    .backgroundComunity {
        background-position-x: center;
        background-position-y: calc(0%);
        background-size: 140%;
        font-size: 1.2rem;
        transform: translate(0%, -5%);
    }
    .imgcontainer{
        max-height: 50vh;
    }
    .rowcontenttop{
        width: 100%;
        min-height: auto;
        transform: translate(0%, 0%);
        -webkit-transform: translate(0%, 0%);
        font-size: 1.2rem;
        position: absolute;
        left: 4.6%;
        top: 11%;
    }
    .rowcontenttop .col-12{
        height: 15vh;
    }

    .rowcontentbott{
        width: 100%;
        min-height: auto;
        bottom: 29%;
        left: 4.6%;
    }
    .rowcontentbott .col-12{
        height: 15vh;
    }
}
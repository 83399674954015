.progress-tracker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: 40px auto 20px auto;
	padding: 0;
	list-style: none;
}

.progress-step {
	display: block;
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 0%;
	flex: 1 1 0%;
	margin: 0;
	padding: 0;
	min-width: 28px;
}

.progress-step:last-child {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.progress-step:not(:last-child)::after {
	content: '';
	display: block;
	position: absolute;
	z-index: 1;
	top: 12px;
	bottom: 12px;
	right: -14px;
	width: 100%;
	height: 2.5px;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.progress-step.is-active .progress-title {
	font-weight: 400;
}

.progress-step > a {
	display: block;
}

.progress-marker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	z-index: 20;
	width: 28px;
	height: 28px;
	padding-bottom: 2px;
	color: #fff;
	font-weight: 400;
	border: 2px solid transparent;
	border-radius: 50%;
	-webkit-transition: background-color, border-color;
	transition: background-color, border-color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

.progress-text {
	display: block;
	padding: 14px 9.3333333333px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.progress-title {
	margin-top: 0;
}

.progress-step .progress-marker {
	color: #fff;
	background-color: #D3D3D3 !important;
}

.progress-step::after {
	background-color: #c2c2c2;
}

.progress-step .progress-text,
.progress-step .progress-step > a .progress-text {
	color: #333333;
}

.progress-step.is-active .progress-marker {
	background-color: #2196f3;
}

.progress-step.is-complete .progress-marker {
	background-color: #1976d2;
}

.progress-step.is-complete::after {
	background: hsla(182, 96%, 46%, 1);
	background: linear-gradient(0deg, #06c7f2 55%, #023473 100%);
	background: -moz-linear-gradient(0deg, #06c7f2 55%, #023473 100%);
	background: -webkit-linear-gradient(0deg, #06c7f2 55%, #023473 100%);
	filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#05DDE4", endColorstr="#023473", GradientType=1 );
}

.progress-step:hover .progress-marker {
	background-color: #56adf5;
}

.progress-tracker--center .progress-step {
	text-align: center;
}

.progress-tracker--center .progress-step:last-child {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.progress-tracker--center .progress-step::after {
	top: 2%;
	right: -54%;
}

.progress-tracker--center .progress-marker {
	margin-left: auto;
	margin-right: auto;
}

.progress-tracker--right .progress-step {
	text-align: right;
}

.progress-tracker--right .progress-step:last-child {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.progress-tracker--right .progress-step::after {
	right: calc(-100% + 14px);
}

.progress-tracker--right .progress-marker {
	margin-left: auto;
}

.progress-tracker--border {
	padding: 5px;
	border: 2px solid #868686;
	border-radius: 38px;
}

.progress-tracker--spaced .progress-step::after {
	width: calc(100% - 33px);
	margin-right: 24px;
}

.progress-tracker--word {
	padding-right: 38.6666666667px;
	overflow: hidden;
}

.progress-tracker--word .progress-text {
	display: inline-block;
	white-space: nowrap;
}

.progress-tracker--word .progress-title {
	margin: 0;
}

.progress-tracker--word-center {
	padding-right: 38.6666666667px;
	padding-left: 38.6666666667px;
}

.progress-tracker--word-center .progress-text {
	padding-right: 0;
	padding-left: 0;
	-webkit-transform: translateX(calc(-50% + 14px));
	transform: translateX(calc(-50% + 14px));
}

.progress-tracker--word-right {
	padding-right: 0;
	padding-left: 38.6666666667px;
}

.progress-tracker--word-right .progress-text {
	padding-left: 0;
	-webkit-transform: translateX(calc(-100% + 28px));
	transform: translateX(calc(-100% + 28px));
}

.progress-tracker--text .progress-step:last-child {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.progress-tracker--text-top .progress-step::after {
	top: auto;
}

.progress-tracker--text-top .progress-text {
	height: 100%;
}

.progress-tracker--text-top .progress-marker {
	bottom: 28px;
}

.progress-tracker--text-inline .progress-step {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.progress-tracker--text-inline .progress-text {
	position: relative;
	z-index: 30;
	max-width: 70%;
	white-space: nowrap;
	padding-top: 0;
	padding-bottom: 0;
	background-color: #fff;
}

.progress-tracker--text-inline .progress-title {
	margin: 0;
}

.progress-tracker--square .progress-step {
	padding-top: 0;
}

.progress-tracker--square .progress-marker {
	-webkit-transform: scaleX(0.33) translateY(-12px);
	transform: scaleX(0.33) translateY(-12px);
	border-radius: 0;
}

@media (max-width: 399px) {
	.progress-tracker-mobile {
		overflow-x: auto;
	}
	.progress-tracker-mobile .progress-tracker {
		min-width: 200%;
	}
}

.progress-tracker--vertical {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.progress-tracker--vertical .progress-step {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.progress-tracker--vertical .progress-step::after {
	right: auto;
	top: 14px;
	left: 12px;
	width: 4px;
	height: 100%;
}

.progress-tracker--vertical .progress-marker {
	position: absolute;
	left: 0;
}

.progress-tracker--vertical .progress-text {
	padding-top: 7px;
	padding-left: 42px;
}

.progress-tracker--vertical .progress-step:not(:last-child) .progress-text {
	padding-bottom: 42px;
}

@-webkit-keyframes scale-up {
	from {
		opacity: 1;
		-webkit-transform: translate(-50%, -50%) scale(0);
		transform: translate(-50%, -50%) scale(0);
	}
	to {
		opacity: 0;
		-webkit-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
	}
}

@keyframes scale-up {
	from {
		opacity: 1;
		-webkit-transform: translate(-50%, -50%) scale(0);
		transform: translate(-50%, -50%) scale(0);
	}
	to {
		opacity: 0;
		-webkit-transform: translate(-50%, -50%) scale(1);
		transform: translate(-50%, -50%) scale(1);
	}
}

.anim-ripple .progress-marker::before,
.anim-ripple-large .progress-marker::before,
.anim-ripple-splash .progress-marker::before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 30;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	visibility: hidden;
}

.anim-ripple :not(:active) .progress-marker::before,
.anim-ripple-large :not(:active) .progress-marker::before,
.anim-ripple-splash :not(:active) .progress-marker::before {
	-webkit-animation: scale-up 0.3s ease-out;
	animation: scale-up 0.3s ease-out;
}

.anim-ripple :focus .progress-marker::before,
.anim-ripple-large :focus .progress-marker::before,
.anim-ripple-splash :focus .progress-marker::before {
	visibility: visible;
}

.anim-ripple-large .progress-marker::before {
	width: 200%;
	height: 200%;
}

.anim-ripple-splash .progress-marker::before {
	width: 200%;
	height: 200%;
	box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.35);
}

.anim-ripple-double .progress-marker::before,
.anim-ripple-double .progress-marker::after {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 30;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0);
	transform: translate(-50%, -50%) scale(0);
	visibility: hidden;
	background: none;
	border: 3px solid rgba(0, 0, 0, 0.3);
}

.anim-ripple-double :not(:active) .progress-marker::before,
.anim-ripple-double :not(:active) .progress-marker::after {
	-webkit-animation: scale-up 0.3s ease-out 0s;
	animation: scale-up 0.3s ease-out 0s;
}

.anim-ripple-double :not(:active) .progress-marker::after {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}

.anim-ripple-double :focus .progress-marker::before,
.anim-ripple-double :focus .progress-marker::after {
	visibility: visible;
}

.anim-path .progress-step::after {
	background-image: -webkit-linear-gradient(left, #b6b6b6 50%, #868686 50%);
	background-image: linear-gradient(to right, #b6b6b6 50%, #868686 50%);
	background-size: 200% 100%;
	background-position: 0% 100%;
	-webkit-transition: background-position 0.3s ease-out;
	transition: background-position 0.3s ease-out;
}

.anim-path .progress-step.is-complete::after {
	background-position: -100% 100%;
}

/**
  * [Progress Text]
  */
@media (max-width: 991px) {
	.progress-container {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.progress-tracker {
		transform: rotate(90deg);
		transform-origin: center;
		margin: 80px 0;
		padding: 20px 0;
		width: 370px;
	}
	.progress-text {
		transform: rotate(270deg);
		overflow: visible;
		margin: 40px 0 0 -70px;
		text-align: left;
		width: 210px;
	}
	.progress-title {
		margin-top: 20px;
	}
	.progress-tracker--center .progress-step::after {
		top: 10%;
		right: -50%;
	}
	.progress-marker{
		left: -9px;
		top: 10px;
	}
}

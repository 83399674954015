.footer-bg{
	background: #e8e8e8;
	padding-top: 17px;
	padding-bottom : 47px;
	min-height: 302px;
}

.footer-icon{
	width: 36px;
	height: 36px;
	margin-right: 5px;
	border-radius: 3px;
}

.footer-link{
	font-family: 'Metropolis-Regular';
	color: #575756;
	font-size: 1.125rem;
	font-weight: 300;
}

.footer-link:hover{
	cursor: pointer;
	text-decoration: none;
	color: #000000;
}

.footer-link:visited{
	color: #000000;
}

/* 
.footer-subtitles{
		font-style: linear-gradient(to right, #0095FF, #6DFFFF); ;
		writing-mode: vertical-lr;
		transform: rotate(180deg);
		font-size: 1.5rem;
		font-weight: 500;
		
} */


  
  .horizontal {
	writing-mode: horizontal-tb;
	padding-left: 20%;
	padding-top: 5%;
  }
  
  .vertical {
	font-size: 2.5rem;
	font-weight: 900;
	font-family: 'Metropolis-Medium';
	writing-mode: vertical-rl;
	position: absolute;
	padding-bottom: 5%;
	transform: rotate(180deg);
	background: linear-gradient(to BOTTOM, #017ad1, #6DFFFF );
    -webkit-background-clip: text;
  	-webkit-text-fill-color: transparent;
  }

  @media (max-width: 800px) {
	.vertical{
	  font-size: 1.8rem;
	  left: 0%;
	  padding: 0%;
	}
  }
.tarjet-info{
   -webkit-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   border: 0;
   background: hsla(6, 100%, 65%, 1);
   background: linear-gradient(0deg, hsla(6, 100%, 65%, 1) 0%, hsla(355, 86%, 62%, 1) 60%);
   background: -moz-linear-gradient(0deg, hsla(6, 100%, 65%, 1) 0%, hsla(355, 86%, 62%, 1) 60%);
   background: -webkit-linear-gradient(0deg, hsla(6, 100%, 65%, 1) 0%, hsla(355, 86%, 62%, 1) 60%);
   filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF5B4A", endColorstr="#F24C5A", GradientType=1 );
   color: #F5F5F5;
   font-size: .8rem;
   box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
   border-radius: 1rem;
 }

 .tarjet-info:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  box-shadow: #bbc7c7 10px 10px 10px;
 }



 .tarjet-info2{
  -webkit-transform: translateY(0);
   -ms-transform: translateY(0);
   transform: translateY(0);
   -webkit-transition: all 0.3s ease-in-out;
   -moz-transition: all 0.3s ease-in-out;
   transition: all 0.3s ease-in-out;
   border-top: 4px solid #3ACFD5;
   border-radius: 15px;
   border-bottom: 4px solid #3a4ed5;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
   background-position: 0 0, 100% 0;
   background-repeat: no-repeat;
   -webkit-background-size: 20px 100%;
   -moz-background-size: 20px 100%;
   background-size: 2px 100%;
   background-image: -o-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%), -o-linear-gradient(top, #3acfd5 0%, #3a4ed5 100%);
   background-image: linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%), linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%);
 }


.tarjet-info2:hover {
   -webkit-transform: translateY(-10px);
   -ms-transform: translateY(-10px);
   transform: translateY(-10px);
   box-shadow: #bbc7c7 10px 10px 10px;
   
 }


 .brokers-container{
   background-color: #ffffff !important;
   padding: 0px;
 }
 
 .brokers-header{
   position: relative;
   max-height: 80vh;
   overflow: hidden;
 }
 
 .brokers-header-button{
   width: 20rem;
   position: absolute;
   left: 50%;
   right: 50%;
   top: 80%;
   transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   background-color: #F24C5A;
   font-family: 'Metropolis-Regular';
   font-weight: normal;
 }
 
 .video-brokers{
   object-fit: fill;
   width: 100%;
   height: 350px;
   border: 0;
   padding: 0;
 }
 

 
 .titulos{  
   font-size: 42px;  
   background: -webkit-linear-gradient(to right, #0095FF 45%, #6DFFFF 65%);
   background: linear-gradient(to right, #0095FF 45%, #6DFFFF 65%);
   -webkit-background-clip: text;  
   -webkit-text-fill-color: transparent;  
 }  
 
 @media (min-width: 768px) {
   .titulos{
     font-size: 38px;
   }
 }
 
 @media (min-width: 992px) {
   .titulos{
     font-size: 72px;
   }
 }
 
 .alliesc{
     display: block !important;
     margin: 0 auto !important;
     margin-top: 20px !important;
     margin-bottom: 20px !important;
     width: 100% !important;
 }
 
 .carousel-control-prev-icon{
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
 }
 
 .carousel-control-next-icon{
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
 }
 
 .carousel-indicators li {
     background-color: #000 !important;
 }
 
 .quote-card {
   background: transparent;
   color: #222222;
   padding: 20px;
   padding-left: 70px;
   box-sizing: border-box;
   position: relative;
   overflow: hidden;
   min-height: 120px;
   font-family: 'Source Sans Pro', sans-serif;
   font-weight: 300;
 }
 
 .quote-card p {
   font-size: 22px;
   line-height: 1.5;
   margin: 0;
   max-width: 80%;
   font-weight: 900;
   z-index: 1;
 }
 
 .quote-card cite {
   font-size: 16px;
   margin-top: 10px;
   display: block;
   font-weight: 200;
   opacity: 0.8;
 }
 
 .quote-card:before {
   font-family: Georgia, serif;
   content: "“";
   position: absolute;
   top: 10px;
   left: 10px;
   font-size: 5em;
   color: rgba(238, 238, 238, 0.8);
   font-weight: normal;
 }
 
 .quote-card:after {
   font-family: Georgia, serif;
   content: "”";
   position: absolute;
   bottom: -110px;
   line-height: 100px;
   right: -17px;
   font-size: 25em;
   color: rgba(238, 238, 238, 0.8);
   font-weight: normal;
   z-index: -1;
 }
 
 .title-bd{
   text-align: center;
 }
 
 .brokers-container .title{
   font-size: 3rem !important;
   color: #EF4E5B;
 }
 .brokers-container .title-dp-blue{
   color: #213970;
 }
 
 @media (max-width: 800px) {
   .title-bd{
     text-align: left;
   }
 }
 
 .brokers-footer{
   position: relative;
 }
 
 .brokers-footer img{
   min-height: 125px;
 }
 
 .brokers-button-final{
   font-family: 'Metropolis-Regular';
   width: 20rem !important;
   position: absolute;
   left: 50%;
   right: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   height: 56px;
   border-color: none !important;
   border-radius: 28px;
   box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
   border: solid 1px rgba(255, 255, 255, 0.1);
   background-color: var(--primary-color);
   color: #ffffff;
   font-size: 1.5rem !important;
 }
 
 @media (min-width: 768px) {
   .brokers-button-final{
     width: 300px;
     height: 56px;
     font-size: 1.5rem !important;
   }
 }
 
 .brokers-button-final:hover{
   border: none !important;
   border-color: none !important;
   border-radius: 28px;
   box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
   background-color: #F24C5A;
   cursor: pointer;
 }
 
 .float-button{
   position: fixed; 
   bottom: 15px; 
   right: 15px;
   z-index: 999;
 }
 
 .float-button-dp{
   text-align: center;
   font-family: 'coolvetica_rg';
   cursor: pointer;
   border-radius: 28px;
   box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
   border: solid 1px rgba(255, 255, 255, 0.1);
   background-color: #ffffff;
   color: var(--primary-color);
   padding: 5px 20px;
   transition: all ease 0.2s;
   width: 120px;
   font-size: 13px;
 }
 
 #float-button-dp{
   display: none;
 }
 
 .float-button-dp:hover{
   border-color: transparent;
   background-color: var(--primary-color);
   color: #ffffff;
   cursor: pointer;
 }
 
 @media (min-width: 768px) {
   .float-button-dp{
     width: 190px;
     font-size: 16px;
   }
 }

 .fa-border-radius{
    border-radius: 10% 25%;
 }
 
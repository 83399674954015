.card-info{
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
  background: rgb(255, 255, 255);
  color: #000000;
  font-size: .8rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border-radius: 1rem;
  height: 100%;
  width: 100%;
}

.card-info:hover {
 -webkit-transform: translateY(-10px);
 -ms-transform: translateY(-10px);
 transform: translateY(-10px);
 box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
/*
.dp-opciones:before{
    background-image: url(../assets/img/home/ICONO1.png);
}

.dp-15min:before{
  background-image: url(../assets/img/home/ICONO2.png);
}

.dp-linea:before{
  background-image: url(../assets/img/home/ICONO3.png);
}
*/

.background{
  background-image:url(../assets/img/home/circulo_fondo.png) ;
}


#header {
    padding: .75rem 1.25rem;
    background-color: transparent;
    border-bottom: none;
}

#footer {
    padding: .75rem 1.25rem;
    background-color: transparent;
}

#btn-white{
  background-color: white;
  border: none;
}

#btn-white:hover{
  background-color: #ececec;
  color: #5f5f5f !important;
}

@media (min-width: 992px){
  .flex30{
    flex: 0 0 27% !important;
    max-width: 50% !important;
  }
}

.tooltip-password>.tooltip-inner {
	max-width: none;
	width: inherit;
	background-color: #E8E8E8 !important;
	color: #000000 !important;
}

.btn-register {
	width: 150px;
	height: 45px;
	background: var(--primary-color);
	border-radius: 25px;
	border: none !important;
	color: #ffffff;
}

.btn-register-success {
	width: auto;
	height: 55px;
	line-height: 55px;
	text-decoration: none !important;
	background: var(--primary-color);
	border-radius: 25px;
	border: none !important;
	color: #ffffff;
	padding: 0 1rem;
	opacity: 0.8;
}

.btn-register-success:hover {
	color: #fff;
	opacity: 0.95;
}

/* .btn-broker-container{
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	bottom: 30px;
} */

/* .btn-broker{
	font-size: 16px !important;
	width: 220px !important;
	height: 40px !important;
	border-radius: 35px !important;
}

.btn-broker:hover{
	background-color: white !important;
	color: #023E95 !important;
	border-color: white !important;
} */

/* @media (min-width: 399px) {
	.btn-broker-container{
		bottom: 40px;
	}
} */

/* @media (min-width: 507px) {
	#brokers-carousel .carousel-inner{
		max-height: none !important;
	}
} */

/*SM*/
/* @media (min-width: 576px) {
	.btn-broker{
		font-size: 16px !important;
		width: 220px !important;
		height: 40px !important;
	}
} */

/*MD*/
/* @media (min-width: 768px) {
	.btn-broker{
		font-size: 22px !important;
		width: 250px !important;
		height: 50px !important;
	}
} */

/* @media (min-width: 775px) { */
	/* #brokers-carousel .carousel-inner{
		max-height: 720px !important;
	} */

	/* .btn-broker-container{
		bottom: 0;
	} */

	/* .btn-broker{
		font-size: 12px !important;
		width: 150px !important;
		height: 30px !important;
	} */
/* } */

/*LG*/
/* @media (min-width: 992px) { */
	/* .btn-broker-container{
		bottom: 0;
	} */

	/* .btn-broker{
		font-size: 18px !important;
		width: 220px !important;
		height: 40px !important;
	} */
/* } */

/*XL*/
/* @media (min-width: 1200px) { */
	/* .btn-broker-container{
		bottom: 10px;
	} */

	/* .btn-broker{
		font-size: 16px !important;
		width: 220px !important;
		height: 40px !important;
	} */
/* } */

/*XXL*/
/* @media (min-width: 2000px) { */
	/* .btn-broker{
		font-size: 28px !important;
		width: 340px !important;
		height: 60px !important;
	} */
/* } */

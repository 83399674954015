@import url('./general.css');
.loader-container{
    position: fixed;
    height: 100%;
    width: 100%;
	  background: var(--black16);
    z-index: 100;
}
.lds-ripple {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    top: 48% !important;
    left: 48% !important;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid var(--primary-color);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
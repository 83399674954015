
.dashboard{
    background-color: #f4f4f4 !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#video-dashboard{
    position:fixed;
    min-height: 100%;
    min-height: 100%;

    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);
}

.dashboard-logo{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 230px;
    max-width: 5px;
    z-index: 2;
}

#dashboard-container{
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
    visibility: visible;
    opacity: 1;
        overflow: hidden;

}


/* .dashboard-visible{
    visibility: visible;
    opacity: 1;
}

.dashboard-hidden{
    visibility: hidden;
    opacity: 0;
} */

.dashboard-container{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 50px;
    z-index: 2;
    overflow: hidden;
}

.dashboard-label{
    font-family: 'Metropolis-Light';
    color : white;
    font-size: 2rem !important;
}

.dashboard-label-date{
    font-family: 'Metropolis-Regular';
    color : white;
    font-size: 1rem;
}


/* .label-solicitudes{
    border-bottom: 2px solid #007bff;
}

.label-pymes{
    border-bottom: 2px solid #ffc107;
}

.label-monto{
    border-bottom: 2px solid #28a745;
}

.label-brokers{
    border-bottom: 2px solid #6c757d;
}

.label-financieros{
    border-bottom: 2px solid #dc3545;
} */

.dashboard-data{  
    font-family: 'coolvetica_rg';
    font-size: 4.9rem !important;
    color:#ffff;
} 

@media (min-width: 400px) {
    .dashboard-logo{
        max-width: 28%;
    }

    .dashboard-container{
        margin-top: 50px;
    }
    
    #monto-colocado{
        margin-top: 150px;
    }

    #monto-colocado{
        margin-bottom: 148px;
    }

    .dashboard-label{
    font-size: 1rem !important;
    }

    .dashboard-data{  
    font-size: 2rem !important;
    } 

    .dashboard-label-date{
     font-size: .8rem !important;
    }
}

@media (min-width: 768px) {
    .dashboard-logo{
        max-width: 25%;
    }

    .dashboard-data{
        font-size: 4rem !important;
    }
     #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 95px;
    }

    .dashboard-label{
    font-size: 1.5rem !important;
    }
}

@media (min-width: 992px) {
    .dashboard-label{
        font-size: 1.5rem !important;
    }

    .dashboard-data{
        font-size: 4rem !important;
    }
     #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 95px;
    }
}

@media (min-width: 1200px) {
    .container-xl-fluid{
		max-width: 80% !important;
	}

    .dashboard-logo{
        max-width: 30%;
    }

    .dashboard-label{
        font-size: 2rem !important;
    }

    .dashboard-data{
        font-size: 4.5rem !important;
    }
    #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 145px;
    }
}

@media (min-width: 1500px) {
    .modal-content{
        max-width: 60% !important;
    }

    .modal-title{
        font-size: 2.2rem !important;
    }

    .modal-body{
        font-size: 1.5rem !important;
    }

    .btn-blue-general{
        width: 220px !important;
        max-width: initial !important;
        height: 50px !important;
        border-radius: 50px !important;
        font-size: 1.2rem !important;
    }

    .dashboard-label{
        font-size: 2rem !important;
    }

    .dashboard-data{
        font-size: 6rem !important;
    }

    #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 182px;
    }

    .dashboard-label-date{
     font-size: 1rem !important;
    }
}

@media (min-width: 1800px) {
    .dashboard-logo{
        width: 30% !important;
    }

    .modal-content{
        max-width: 80% !important;
    }

    .modal-title{
        font-size: 2.5rem !important;
    }

    .modal-body{
        font-size: 2rem !important;
    }

    .btn-blue-general{
        width: 240px !important;
        max-width: initial !important;
        height: 60px !important;
        border-radius: 50px !important;
        font-size: 1.4rem !important;
    }
    
    .dashboard-label{
        font-size: 3rem !important;
    }

    .dashboard-data{
        font-size: 6rem !important;
    }

    #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 209px;
    }

    .dashboard-label-date{
     font-size: 1.5rem !important;
    }
}

@media (min-width: 2000px) {
    .dashboard-logo{
        width: 30% !important;
    }

    .dashboard-label{
        font-size: 3rem !important;
    }

    .dashboard-data{  
        font-size: 8rem !important;  
    }

    #monto-colocado{
        margin-top: 68px;
    }

    #monto-colocado{
        margin-bottom: 265px;
    }

    .dashboard-label-date{
     font-size: 2rem !important;
    }
}

@media (min-width: 2500px) {
    .dashboard-logo{
        width: 30% !important;
    }
    .modal-content{
        max-width: 100% !important;
    }

    .modal-title{
        font-size: 2.7rem !important;
    }

    .modal-body{
        font-size: 2.2rem !important;
    }

    .btn-blue-general{
        width: 260px !important;
        max-width: initial !important;
        height: 70px !important;
        border-radius: 50px !important;
        font-size: 1.6rem !important;
    }

    .dashboard-label{
        font-size: 3rem !important;
    }

    .dashboard-data{  
        font-size: 10rem !important;  
    }

    #monto-colocado{
        margin-top: 148px;
    }

    #monto-colocado{
        margin-bottom: 235px;
    }

    .dashboard-label-date{
     font-size: 2rem !important;
    }
}

/* .area{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background-image: linear-gradient(rgb(0, 149, 255 ), rgb(109, 255, 255));
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 100px;
    animation-delay: 35s;
}

.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 35s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 35s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 30s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 30s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

} */

.nav-line {
	border-left: 1px solid var(--black50);
  }
  
  /* NAVBAR */
  .nav-bar {
	background: #fff !important;
	height: auto;
	max-height: 6rem;
  }
  
  @media (max-width: 768px) {
	.nav-bar {
	  height: auto;
	  max-height: 100rem !important;
	}
  }

  .navbar{
	  align-content: center !important;
  }

  .nav-btn {
	color: #575756!important;
	text-decoration: none !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-right: 20px;
	padding-left: 20px;
	cursor: pointer;
  }

  .nav-btn-rad{
	border-radius: 25px;
  }
  
  .nav-btn:hover {
	color: #023473 !important;
	opacity: 0.95;
  }

link {
	color: #2175FF;
	cursor: pointer;
	font-weight: 400;
	text-decoration: none;
  }
  
  .link--arrowed {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
  }
  .link--arrowed .arrow-icon {
	position: relative;
	top: -1px;
	transition: transform 0.3s ease;
	vertical-align: middle;
  }
  .link--arrowed .arrow-icon--circle {
	transition: stroke-dashoffset 0.3s ease;
	stroke-dasharray: 95;
	stroke-dashoffset: 95;
  }
  .link--arrowed:hover .arrow-icon {
	transform: translate3d(5px, 0, 0);
  }
  .link--arrowed:hover .arrow-icon--circle {
	stroke-dashoffset: 0;
  }


/*
  .hvr-underline-from-left {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden; 


  .hvr-underline-from-left::before{
	content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: linear-gradient(to right, #0095FF, #6DFFFF);
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
	

  
	.hvr-underline-from-left:active::before,.hvr-underline-from-left:focus::before,.hvr-underline-from-left:hover::before
	{
		right:0
	}
  */
  
  .nav-btn.primary {
	color: #fff !important;
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
	background: #023473;
	width: 200px;
	font-weight: bold;
	font-family: 'Metropolis-Regular';
  }
  
  .nav-btn.primary:hover {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
	background: #F24C5A!important;
	color: #fff !important;
	transition: ease-in-out 0.1s;
  }
  
  .nav-btn-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: left !important;
	-ms-flex-pack: left !important;
	justify-content: left !important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/* height: 80px; */
  }
  
  .link-header {
	color: var(--primary-color) !important;
  }
  
  .link-header:hover {
	text-decoration: none;
	color: #ffffff !important;
  }
  
  .logout {
	width: 160px;
	background: none !important;
	background-color: none !important;
	color: var(--black50) !important;
	font-size: 0.9em !important;
	padding: 0 !important;
	border: none !important;
  }
  
  .logout:hover {
	width: 160px;
	background: none !important;
	background-color: none !important;
	color: var(--primary-color) !important;
	padding: 0 !important;
	border: none !important;
  }
  
  /* logo */
  .nav-logo {
	width: 115px;
  }
  
  .nav_bar_active{
	  /* color: var(--primary-color);
	  position:relative; */
	  text-align:center;
  	padding-bottom:5px;
  	background: /* gradient can be an image */
    linear-gradient(
      to right, 
      #0095FF, 
      #6DFFFF
    )
    left 
    bottom
    no-repeat; 
  	background-size:100% 5px ;
  }
  /* .nav_bar_active::after{
	content: '';
	height: 3px;
	width: 120px;
	background: var(--primary-color);
	position: absolute;
	bottom: 0;
	margin:auto;
  } */
  .nav-bar-icon-whatsapp{
	position:fixed; 
	bottom: 1rem; 
	right: 1rem;
	font-size: 2rem;
	color: white !important;
	background: #25D366;
	height: 3rem;
	width: 3rem;
	line-height: 3rem;
	text-align: center;
	border-radius: 100%;
	opacity: 0.8;
	cursor: pointer;
	text-decoration: none;
	box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.3);
  }

  .nav-bar-icon-whatsapp:hover{
	  opacity: 1;
  }
  .nav-bar-icon-whatsapp > a {
	color: white !important;
  }

.alianza_div{
	padding: 0 !important;
    max-height: 85vh;
	overflow: hidden;
	display: grid;
	position: relative;
}
.container-fluid .alianza-banner{
	max-inline-size: 100%;
  	block-size: auto;
	object-fit: contain !important;
	/* aspect-ratio: 16/9; */
}

.alianza-banner,
.header-button{
	grid-area: 1/2;
}
.header-button{
	align-self:center;
	position: absolute;
	margin: auto;
	left: 50%;
	transform: translate(-50%,-5%);
}

.header-button{
	width: 15rem;
	height: 3rem;
	border-color: none !important;
	border-radius: 1.75rem;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	border: solid 1px rgba(255, 255, 255, 0.1);
	background-color: #F24C5A;
	font-weight: bold;
	font-family: 'Metropolis-Regular';
}

.header-button:hover{
	border: none !important;
	border-color: none !important;
	border-radius: 28px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	color: #ffffff !important;
	cursor: pointer;
	background-color: #f24c5a !important;
}

/*celular*/
@media (min-width:200px) and (max-width: 375px) and (max-height: 667px){
	.header-button{
		bottom: 5%;
	}
}
@media (min-width:200px) and (max-width: 375px) and (min-height: 668px){
	.header-button{
		bottom: 15%;
	}
}

@media (min-width:376px) and (max-width:576px){
	.header-button{
		bottom: 20% !important;
	}
	.alianza_div{
		max-height: 100vh;
	}
}

@media (min-width:577px) and (max-width:767px){
	.header-button{
		bottom: 20% !important;
	}
	.alianza_div{
		max-height: 100vh;
	}
}

/*Tablet*/

@media (min-width:768px) and (max-width:992px){
	.header-button{
		bottom: 10% !important;
	}
}

@media (min-width:993px) and (max-width:1024px){
	.header-button{
		bottom: 20% !important;
	}
}

/*laptop*/
@media only screen and (min-width: 1025px){
	.header-button{
		left: 17%;
		bottom: 25%;
		/* margin-left: 0; */
	}
}
/*monitor*/
@media (min-width :1900px ){
	.header-button{
		left: 12%;
		bottom: 30%;
		/* margin-left: 0; */
	}
	.alianza_div{
		max-height: 90vh;
	}
}

@import url('../assets/fonts/fonts.css');


:root {
	--primary-color : #023473;
	--secondary-blue : #003c99;
	--dark-blue : #090841;
	--black : #0000;
	--black50 : #00000080;
	--black04 : rgba(0,0,0,0.04);
	--black16 :rgba(0,0,0,0.16);
	--black24 : rgba(0,0,0,0.24);
	--blackNav : #3d3d3d;
}

.list-broker{
	padding-left: .9rem !important;
}

.casa{
	padding: 0;
}

.react-responsive-modal-modal{
	top: 30%;
}

.form-checkbox{
	width: 65px;
	height: 60px;
	margin-top: -15px;
}

.modal-dialog{
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-dialog .modal-content{
	width: auto !important;
	max-width:380px;
}
.positionInfo{
	position: relative;
    top: -28px;
	left: 270px;
	width: 25px;
	height: 25px;
}

.bankInfo{
	position: relative;
    top: -48px;
	left: 205px;
	width: 25px;
	height: 25px;
}

.btn-bank-del{
	background-color: transparent !important;
	border: none !important;
	margin-left: -15px;
}

.bankDel{
	margin-top: -2px;
	width: 39.5px;
	height: 39.5px;
}

@media (min-width: 400px){
	.btn-bank-del{
		margin-top: 15px;
	}
}

@media (min-width: 576px){
	.btn-bank-del{
		margin-top: 0px;
		margin-left: -30px;
	}
}

@media (min-width: 768px){
	.btn-bank-del{
		margin-top: 0px;
		margin-left: -15px;
	}
}

.modal-comparative{
	display: flex; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 100; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-comparative--content{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.modal-comparative--content, .modal-comparative--image{
  	background-color: #fefefe;
  	margin: 2% auto; /* 15% from the top and centered */
  	padding: 20px;
  	border: 1px solid #888;
  	/*width: 90%;*/ /* Could be more or less, depending on screen size */
  	height: 90%;
}

.modal-comparative--image{
	border: none;
	/* margin: 20px 30px; */
	padding: 0;
	width: auto;
	max-width: 1296px;
	max-height: 648px;
}

.divContainer{ display: block;}

.bannerAllies {

	width: 100% !important;
	/* padding-top: 50px; */
}

/* MARGIN TOP */

.mt-420{margin-top: 420px;}

.mt-158{margin-top: 158px;}

.mt-120{margin-top: 120px;}

.mt-100{margin-top: 100px;}

.mt-72{margin-top: 72px;}

.mt-50{margin-top: 50px;}

.mt-45 { margin-top: 45px;}

.mt-40{margin-top: 40px;}

.mt-30{margin-top: 30px;}

.mt-26{margin-top: 26px;}

.mt-24{margin-top: 24px;}

.mt-21{ margin-top: 21px;}

.mt-18{margin-top: 18px;}

.mt-16{margin-top: 16px;}

.mt-11{ margin-top: 11px;}

.mt-10{margin-top: 10px;}

.mt-5{ margin-top: 5px;}

.mt-1{margin-top: -300%;}

/* MARGIN BOTTOM */

.mb-158{margin-bottom: 158px;}

.mb-125{margin-bottom: 129px;}

.mb-120{margin-bottom: 120px;}

.mb-100{margin-bottom: 100px;}

.mb-72{margin-bottom: 72px;}

.mb-50{margin-bottom: 50px;}

.mb-45 { margin-bottom: 45px;}

.mb-40{margin-bottom: 40px;}

.mb-34{ margin-bottom: 34px;}

.mb-30{margin-bottom: 30px;}

.mb-26{margin-bottom: 26px;}

.mb-24{margin-bottom: 24px;}

.mb-21{ margin-bottom: 21px;}

.mb-18{margin-bottom: 18px;}

.mb-16{margin-bottom: 16px;}

.mb-10{margin-bottom: 10px;}

.mb-5{margin-bottom: 5px;}

.mb-3{margin-bottom: 3px;}

.mb-comunity{
	margin-bottom:12%;
}

/* MARGIN LEFT */

.ml-39{margin-left: 39px;}


.justify-right{
	justify-content: right;
}

/* MARGIN LEFT */

.ml-comunity{
	margin-left: 15%;
}

.ml-15 {
	margin-left: 18%;
}

.mr-20{margin-right: -65%;}

/* FONT SIZE */
.fz60 {font-size: 4rem !important;}

.fz56 {font-size: 3.5rem !important;}

.fz50 { font-size: 3.125rem !important; }

.fz52 { font-size: 3.25rem !important;}

.fz48 {font-size: 3rem !important;}

.fz42 {font-size: 2.62rem !important;}

.fz38 {font-size: 2.375rem !important;}

.fz32 {font-size: 2rem !important;}

.fz29 {font-size: 1.81rem !important;}

.fz25 {font-size: 1.56rem !important;}

.fz24 {font-size: 1.5rem !important;}

.fz23 {font-size: 1.43rem !important;}

.fz22 {font-size: 1.37rem !important;}

.fz20 {font-size: 1.25rem !important;}

.fz18 {font-size: 1.125rem !important;}

.fz16 {font-size: 1rem !important;}

.fz15 {font-size: 0.93rem !important;}

.fz12 {font-size: 0.796rem !important;}

.fz10 {font-size: 0.625rem !important;}


/* FONT TYPE */

.coolvetica { font-family: 'coolvetica_rg'; }

.metropolisMed { font-family: 'Metropolis-Medium'; }

.metropolisReg { font-family: 'Metropolis-Regular';}

.metropolisLight { font-family: 'Metropolis-Light';}

.metropolisBold { font-family: 'Metropolis-Bold';}

.metropolisBlack { font-family: 'Metropolis-Black';}

.lucida { font-family: 'lucida_grande' }

/* FONT WEIGHT */

.fw200{font-weight: 200; }

.fw300{ font-weight: 300;}

.fw400{ font-weight: 400;}

.fw500{ font-weight: 500;}

.fw600{ font-weight: 600;}

/* LINE HEIGHT */

.lh15{ line-height: 1.5; }

.ls-11 { letter-spacing: 1.1px  }

.ls-01{ letter-spacing: 0.1px}

.lh133{  line-height: 1.33}

/* FONT COLOR */
.white{ color: #ffffff !important;}

.gray50{ color: var(--black50) !important;}

.gray04{ color: var(--black40) !important;}

.gray24 { color: var(--black24) !important;}

.gray16 { color : var(--black16) !important;}

.blue-primary { color: var(--primary-color) !important;}

.blackNav { color: var(--blackNav) !important;}

.blackBlue { color : var(--dark-blue) !important;}

/* BUTTON WIDTH */

.w-160{ width: 160px;}

/* PADDINGS */

.plr-0 {padding-left: 0px; padding-right: 0px;}

.plr-10{ padding-top: 1px;}

.plr-20{ padding-left: 20px; padding-right: 20px;}

.plr-60 {padding-left: 60px; padding-right: 60px;}

.pt-40 {padding-top: 40px;}

.pt-120{ padding-top: 120px;}

.ptb-120{ padding-top: 120px; padding-bottom: 120px; }

.ptb-60 { padding-top: 60px; padding-bottom: 60px;}

.pb-120 {padding-bottom: 120px;}

.pb-70 {padding-bottom: 70px;}

.pb-40 {padding-bottom: 40px;}

.pb-20 {padding-bottom: 20px;}
/*	OPACITY	*/

.opacity-50{ opacity: 0.5 }
.opacity-75{ opacity: 0.75 }

/* Inputs */

.custom-form-input{
	border: 1px solid #000000 !important;
	border-radius: 5px !important;
	font-size: 17px !important;
	max-width: 690px;
	height: 50px;
}

.error{
	color: firebrick;
}

/* Tooltips */

.tooltip-custom{
	background-color: #E8E8E8 !important;
	color: #000000
}

/* BUTTONS */

/* .btn-blue-general, */
.btn-blue-documents,
.btn-blue-send-documents,
.btn-blue-status{
	font-family: 'coolvetica_rg' !important;
	font-size: 17px !important;
	color: #ffffff !important;
	background-color: #023473 !important;
	border-color: #023e95 !important;
	border-radius: 25px !important;
	/* width: 100% !important;	 */
	height: 45px !important;
	text-align: center !important;
	letter-spacing: 2px;
}

.btn-blue-status{
	padding: 0px 15px 0px 15px !important;
}

.btn-blue-general{
	font-weight: bold !important;
	min-width: 200px !important;
	max-width: 500px !important;
	font-family: 'Metropolis-Regular' !important;
	font-size: 1rem !important;
	color: #ffffff !important;
	background-color: #023473 !important;
	border-color: #023e95 !important;
	border-radius: 25px !important;
	/* width: 100% !important;	 */
	height: 45px !important;
	text-align: center !important;
	letter-spacing: 2px;
}

.btn-blue-brokers{
	font-family: 'coolvetica_rg' !important;
	font-size: 12px !important;
	color: #ffffff !important;
	background-color: #023E95 !important;
	border-color: #023e95 !important;
	width: 100% !important;	
	height: 40px;
	text-align: center !important;
	letter-spacing: 2px;
	margin-top: 23px;
}

.btn-open-banking{
	width: 200px !important;
	font-size: 15px !important;
}

.btn-open-banking:disabled{
	cursor: no-drop !important;
}

.btn-blue-send-documents{
	max-width: 500px !important;
	font-size: .9rem !important;
}

.btn-blue-general:hover:not(:disabled),
.btn-blue-documents:hover:not(:disabled),
.btn-blue-status:hover:not(:disabled){
	opacity: 0.95;
}

.btn-gray-general{
	font-family: 'coolvetica_rg' !important;
	font-size: 17px !important;
	font-weight: normal !important;
	color: #ffffff !important;
	background-color: gray !important;
	border-color: gray !important;
	border-radius: 25px !important;
	width: 40% !important;	
	height: 45px !important;
	text-align: center !important;
	letter-spacing: 2px;
}

.btn-blue-documents{
	max-width: 1000px;
}


.link-blue-general{
	text-decoration: none !important;
	padding-top: 10px !important;
}

.link-blue-general:hover:not(:disabled){
	opacity: 0.95;
}

.btn-blue-secondary{
	font-family: 'coolvetica_rg' !important;
	font-size: 17px !important;
	color: #ffffff !important;
	background-color: #2A2E59 !important;
	border-color: #2A2E59 !important;
	border-radius: 25px !important;
	width: 100% !important;	
	height: 45px !important;
	text-align: center !important;
	letter-spacing: 2px;
}

/* MODAL */

.styles_modal__gNwvD{
	border-radius: 5px !important;
	padding: 30px !important;
}

/* FORMS */

.subtitle.form{
	font-size: 1.5em;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 0px;
	font-family: 'coolvetica_rg';
	text-transform: lowercase;
	color: #F24C5A;
}

.black-link{
	color: #000000 !important;
}

.black-link:hover{
	color: #000000 !important;
	text-decoration: none;
}

/* Text */
.title-cards-dp{
	font-family: 'Metropolis-Bold';
	color: #3D3D3D;
}

.title-dp{
	font-family: 'coolvetica_rg';
	
	color: #F24C5A;
	text-transform: lowercase;
}

.title-dp-coral{
	font-family: 'coolvetica_rg';
	color: #F24C5A;
}

.title-dp-blue{
	font-family: 'coolvetica_rg';
	color: #023473;
}

.subtitle-dp{
	font-family: 'Metropolis-Regular';
	color: #3D3D3D;
}

.text-dp{
	font-family: 'Metropolis-Regular';
	color: #3D3D3D;
}

.text-dp-white{
	font-family: 'Metropolis-Regular';
	color: #FFFFFF;
}

.text-dp-white-bold{
	font-family: 'Metropolis-Bold';
	color: #FFFFFF;
}

.text-dp-gray{
	font-family: 'Metropolis-Regular';
	color: #575756;
}



.line-height{
	line-height: 2rem;
}

.text-form-dp { color: #333333 !important; }

.text-msg-dp { color: #023473 !important; }

/* 
@media (min-width: 320px) and (max-width: 425px){
	body{
		font-size: 13px !important;
	}
} */

/* Max width */

.mxw-160{
	max-width: 160px;
}

@media only screen and (min-width: 1000px){
    .text.description.big{
        font-size: 1.75em !important;
	}
	html, body{
		font-size: 18px;
	}

}

@media only screen and (max-width: 995px){
    /* .fz56{
        font-size: 2.2em !important;
	}

	.fz42{
		font-size: 2em;
	}
	
	.fz24{
		font-size: 1.3em;
	}

	.fz29{
		font-size: 1.5em;
	} */
}

@media(max-width: 767px){
	.form-checkbox{
		width: 80px;
	}
}

.heigth-45{
	height: 45px !important;
}

.recomendation-box{
	padding: 30px;
	width: 100%;
	max-width: 1200px;
	height: 167px;
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
}

.dropdown-box{
	width: 100%;
	max-width: 1200px;
	min-height: 98px;
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	padding-top: 27px;
	padding: 27px;
	padding-left: 25px;
	transition: 2s;
}

.open-dropdown{
	transition: 3s;
}

.dropdown-box .text-dropdown{
	display: none;
	opacity: 0%;
}

.arrow-dropdown:hover{
	cursor: pointer;
}

.registro-exitoso{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

/*Cliengo*/
#popupIframe, #chatIframe {float:left!important;right:auto!important;}
#wspIframe {float:right!important;left:auto!important; right: 0px !important;}

.styleCover{
	background-color: #ffffff !important;
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	z-index: 199 !important;
}

.estatus-solicitud-box{
	width: 100%;	
	border: 1px solid transparent;
	border-radius: 5px;
	background-image: linear-gradient(white, white), 
					  linear-gradient(to right, #0095FF 0%, #6DFFFF 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	padding: 1px;
}

.imgAlianza{
	width: 10rem;
}

.imgAlianzaBrokers{
	width: 8.5rem;
	margin-left: 10px;
}



/* .ASPIRIA{
	margin-top: 22px;
	height: 40px;
}

.IMPULSO{
	margin-top: 5px;
}

.MICRO{
	margin-top: 20px;
	height: 40px;
} */

.forceFullWidth{
	width: 100% !important;
	max-width: 100% !important;
	min-width: 100% !important;
}

.lg1{
	line-height: 1;
}
.lg2{
	line-height: 2.3rem;
}

.hoverc{
	color: #023473;
	font-family: 'coolvetica_rg';
}

.hoverc:hover{
	cursor: pointer;
	color: #F24C5A;
	transition: ease-in-out;
}


.input-doubt{
	width: 100%;
	/* max-width: 570px; */
	border-radius: 5px;
	border: 1px solid var(--black24);
	height: 50px;
	padding-left: 15px;
}

.contact-button{
	border: none !important;
	width: 150px;
	height: 58px;
	background: var(--dark-blue) !important;
	border-radius: 29px !important;
}


.text-contacto{
	margin-left: 10%;
}

.chica-left{
	margin-right: 10%;
}

h1{
	font-family: 'coolvetica_rg';
	color: #F24C5A;
	font-size: 4.5rem;
}

@media (max-width: 600px) {
	.text-contacto{
		margin-left: 0;
	}
	.chica-left{
		margin-right: 0;
	}
	h1{
		font-size: 3.5rem;
	}
}



.contact-button{
	width: 150px;
	height: 46px;
	border-color: none !important;
	border-radius: 28px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	border: solid 1px rgba(255, 255, 255, 0.1);
	background-color: #F24C5A !important;
	font-weight: bold;
	font-family: 'Metropolis-Regular';
}

.contact-button:hover{
	border: none !important;
	border-color: none !important;
	border-radius: 28px;
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.6);
	background-color: #023473 !important;
	color: #ffffff !important;
	cursor: pointer;
	
}